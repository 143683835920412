<template>
  <div id="app">
    <Nav :pt="isIndex" />
    <div class="pageMain">
      <div class="p20">
        <h3 class="viewTitle">{{ detailInfo.title }}</h3>
        <div class="viewAuthor">
          {{ __commonJs["happenTimeFun"](detailInfo.inputtime * 1000, "ymd")
          }}<br />苏州民族管弦乐团
        </div>
        <div class="viewMainBg mt20">
          <div class="viewMain">
            <div v-html="contents"></div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  </div>
</template>

<style scoped></style>
<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      id: "",
      detailInfo: {},
      isIndex: false,
      type:'',
    };
  },
  computed: {
    contents() {
      let content = this.detailInfo.content || "";
      let newStr = content.replace(
        /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
        function (match, capture) {
          if (!/http|https/.test(capture)) {
            return `<img src="https://www.suzhousco.com${capture}" alt="" />`;
          } else {
            return '<img src="' + capture + '" alt="" />';
          }
        }
      );
      return newStr;
    },
  },
  created() {
    this.id = this.$route.params.id || 0;
    this.type = this.$route.query.type || "";
    this.getNewsDetail();
  },
  methods: {
    // 获取新闻详情
    getNewsDetail() {
      let requestUrl = "/cms/api/newsdetail";
       if (this.type == 'pinpaigongyi') {
        requestUrl = "cms/api/huimindetail";
      }
      this.$ajax
        .get(requestUrl, {
          id: this.id,
        })
        .then((res) => {
          if (res.code == "200") {
            this.detailInfo = res;
          }
        });
    },
  },
};
</script>
<style>
.pageMain img {
  max-width: 100%;
}
</style>
